import { RmaData } from '../components/RmaPage';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import dayjs from 'dayjs';

const generatePackingSlip = (data: RmaData) => {
  console.log('JSPDF sdf');
  const doc = new jsPDF();

  //Make Header
  autoTable(doc, {
    styles: {
      cellPadding: 1,
    },
    headStyles: {
      halign: 'center',
      fillColor: 'gray',
      textColor: 'white',
      fontSize: 8,
    },
    head: [['Packing Slip']],
  });

  // RMA and Image
  doc.setFontSize(18);
  doc.setFont('helvetica', 'bold');
  doc.text('MM' + data.rma_number.toString(), 14, 27);
  doc.setFontSize(8);

  doc.addImage('/assets/manamed-logo-black.png', 'PNG', 150, 21, 45, 4.5);

  // Ship to section
  autoTable(doc, {
    theme: 'plain',
    startY: 35,
    styles: { cellPadding: 0, fontSize: 8 },
    margin: { right: 100, left: 30 },
    columnStyles: {
      1: { cellWidth: 40 },
    },
    body: [
      [
        {
          content: 'Return to:',
          styles: {
            cellWidth: 18,
            fontStyle: 'bold',
            cellPadding: { right: 2 },
            halign: 'right',
          },
        },
        'Returns Department',
      ],
      ['', 'ManaMed Inc.'],
      ['', '5240 W. Charleston Blvd.'],
      ['', 'Las Vegas, NV 89146'],
    ],
  });

  // Customer Info Section
  autoTable(doc, {
    startY: 35,
    theme: 'plain',
    styles: { cellPadding: 0.5, fontSize: 8 },
    columnStyles: {
      0: { fontStyle: 'bold', halign: 'right', cellPadding: { right: 2 } },
    },
    margin: { left: 100, right: 30 },
    body: [
      ['Company', data.company],
      ['RMA Date', dayjs(data.created_at).format('MMMM Do, h:m A')],
      ['Sales Order', data.sales_order],
      ['Phone', data.phone],
      ['Email', data.email],
    ],
  });

  //Make Header
  autoTable(doc, {
    startY: 68,
    theme: 'plain',
    styles: {
      cellPadding: 0.5,
      fontSize: 8,
    },
    headStyles: {
      halign: 'left',
      fillColor: 'gray',
      textColor: 'white',
      fontSize: 8,
      cellPadding: 1,
    },
    html: '#items-table',
  });

  doc.save('RMA MM' + data.rma_number + ' Packing Slip');
};
export default generatePackingSlip;
