import React, { useEffect, useState } from 'react';
import SEO from './SEO';
import useSWR from 'swr';
import { PulseLoader } from 'react-spinners';
import styled from 'styled-components';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { HiDownload, HiOutlineTag } from 'react-icons/hi';
import generatePackingSlip from '../util/generatePackingSlip';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import symbol from '../images/logos/manamed-symbol.svg';

dayjs.extend(advancedFormat);

type RmaItem = {
  id: string;
  quantity: number;
  reason_for_return: string;
  sku: string;
  quantity_received: null | number;
  quantity_reusable: null | number;
  product_name: string;
};

type FormData = {
  name: string;
  phone: string;
  company: string;
  // numberOfBoxes: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
};

interface TrackingNumber {
  id: string;
  tracking_number: string;
  status: string;
  rma_id: string;
}

export type RmaData = {
  items: RmaItem[];
  id: string;
  company: string;
  airtable_id: null | string;
  created_at: string;
  customer_comment: string;
  email: string;
  full_name: string;
  rma_number: number;
  invoice: null | string;
  status: 'pending' | 'received' | 'closed' | 'authorized';
  sales_order: null | string;
  phone: string;
  resolution: null | string;
  trackingNumbers: TrackingNumber[];
};

const colors = {
  pending: '#8ae8e8',
  authorized: '#cafbff',
  received: '#84cc67',
  closed: '#b68fda',
};

const getRma = async (_: string, id: string) => {
  const response = await fetch(process.env.GATSBY_RMA_API_URL + '/rmas/' + id);
  const json = await response.json();
  if (!response.ok)
    throw new Error('There was a server error with your request.');
  console.log(json.data);
  return json.data as RmaData;
};

const RmaPage: React.FC<{ id: string }> = ({ id }) => {
  const { data, error } = useSWR(['rma', id], getRma);
  const [showLabelModal, setShowLabelModal] = useState(false);
  const [generateLabelLoading, setGenerateLabelLoading] = useState(false);
  const [labelError, setLabelError] = useState(false);
  const [trackingNumbers, setTrackingNumbers] = useState<string[]>([]);

  const { register, handleSubmit, errors: formErrors } = useForm<FormData>({
    mode: 'onBlur',
  });

  const onSubmit = handleSubmit(async (formData) => {
    // const length = parseInt(formData.numberOfBoxes);
    try {
      setGenerateLabelLoading(true);
      setLabelError(false);

      const response = await fetch('/.netlify/functions/createLabel', {
        method: 'POST',
        body: JSON.stringify({
          name: data?.full_name,
          company: data?.company,
          phone: data?.phone,
          rmaNumber: data?.rma_number.toString(),
          addressLine1: formData.address1,
          addressLine2: formData.address2,
          city: formData.city,
          state: formData.state,
          zip: formData.zip,
          rmaId: data?.id,
        }),
      });
      if (!response.ok) {
        throw Error(JSON.stringify(await response.json()));
      }
      const json = await response.json();

      const responseDataSchema = z.object({
        data: z.object({
          labelURL: z.string(),
          trackingNumber: z.string(),
          labelData: z.string(),
        }),
      });

      console.log('RESPONSE FROM FUNCTION:', json);
      const responseData = responseDataSchema.parse(json);
      const trackingNumber = responseData.data.trackingNumber;
      const base64Data = responseData.data.labelData;

      const dataURL = `data:application/pdf;base64,${base64Data}`;
      console.log('Slice of DataURL:', dataURL.slice(0, 50));

      const newTrackingNumbers = [...trackingNumbers];
      newTrackingNumbers.push(trackingNumber);

      setTrackingNumbers(newTrackingNumbers);

      const element = document.createElement('a');
      const fileName = 'manamed-return-label.pdf';
      element.href = dataURL;
      element.download = fileName;
      element.click();
      element.remove();
    } catch (err: unknown) {
      if (err instanceof Error) {
        console.log(err.message);
      } else {
        console.log(err);
      }
      setLabelError(true);
    } finally {
      setGenerateLabelLoading(false);
    }
  });

  if (!data && !error)
    return (
      <>
        <SEO title={`RMA/Return Information`} />
        <Loading>
          <PulseLoader size="40px" color="var(--primary-color)" />
        </Loading>
      </>
    );

  if (data)
    return (
      <>
        <SEO title={`RMA/Return Information`} />
        {showLabelModal && (
          <LabelModal>
            <LabelForm onSubmit={onSubmit}>
              <Instructions>
                Click generate to create and download a return shipping label.
                Generate a new label for each box (UPS does not do multi-package
                return labels at once). <br />
                <br /> If you have too many boxes please email us at
                rma@manamed.com and we'll help out.
              </Instructions>
              {/* <fieldset>
                <label>Number of Boxes</label>
                <select name="numberOfBoxes" ref={register({ required: true })}>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </fieldset> */}
              <fieldset>
                <label>Address Line 1</label>
                <input
                  type="text"
                  name="address1"
                  ref={register({ required: true })}
                />
                {formErrors.address1 && (
                  <ErrorMessage>Please enter an address.</ErrorMessage>
                )}
              </fieldset>
              <fieldset>
                <label>Address Line 2 (Optional)</label>
                <input
                  type="text"
                  name="address2"
                  ref={register({ required: false })}
                />
              </fieldset>
              <fieldset>
                <label>City</label>
                <input
                  type="text"
                  name="city"
                  ref={register({ required: true })}
                />
                {formErrors.city && (
                  <ErrorMessage>Please enter a city.</ErrorMessage>
                )}
              </fieldset>
              <fieldset>
                <label>State</label>
                <input
                  maxLength={2}
                  type="text"
                  name="state"
                  ref={register({ required: true, minLength: 2, maxLength: 2 })}
                />
                {formErrors.state && (
                  <ErrorMessage>
                    Please enter a 2 letter state abbreviation.
                  </ErrorMessage>
                )}
              </fieldset>
              <fieldset>
                <label>Zip</label>
                <input
                  type="text"
                  name="zip"
                  ref={register({ required: true })}
                />
                {formErrors.zip && (
                  <ErrorMessage>Please enter a zip.</ErrorMessage>
                )}
              </fieldset>
              <LabelButtonWrapper>
                {trackingNumbers.length > 0 && (
                  <TrackingNumbers>
                    {trackingNumbers.map((tn, i) => (
                      <span key={tn + i}>Label {tn} generated.</span>
                    ))}
                  </TrackingNumbers>
                )}
                {labelError && <LabelError>Error generating label.</LabelError>}

                <LabelButtons>
                  <GenerateButton disabled={generateLabelLoading}>
                    {generateLabelLoading ? (
                      <PulseLoader size="10px" color={'white'} />
                    ) : (
                      'Generate Label'
                    )}
                  </GenerateButton>
                  <FinishButton
                    onClick={() => setShowLabelModal(false)}
                    disabled={generateLabelLoading}
                  >
                    Finish
                  </FinishButton>
                </LabelButtons>
              </LabelButtonWrapper>
            </LabelForm>
          </LabelModal>
        )}
        <Container>
          <Card>
            <TitleBar>
              <TitleAndStatus>
                <img src={symbol} alt="manamed symbol" />
                <h1>MM{data.rma_number}</h1>
                <Status
                  statusColor={data.status ? colors[data.status] : 'gray'}
                >
                  {data.status}
                </Status>
              </TitleAndStatus>
              {data.status === 'authorized' && (
                <LabelAndPackingSlipButtons>
                  <button onClick={() => setShowLabelModal(true)}>
                    Generate Shipping Label(s) <HiOutlineTag />
                  </button>
                  <button onClick={() => generatePackingSlip(data)}>
                    Download Packing Slip <HiDownload />
                  </button>
                </LabelAndPackingSlipButtons>
              )}
            </TitleBar>
            <Card>
              <Fields>
                <Field>
                  <label>Company</label>
                  {data.company}
                </Field>
                <Field>
                  <label>Name</label>
                  {data.full_name}
                </Field>
                <Field>
                  <label>Email</label>
                  {data.email}
                </Field>
                <Field>
                  <label>Phone</label>
                  {data.phone}
                </Field>
                <Field>
                  <label>Sales Order</label>
                  {data.sales_order}
                </Field>
                <Field>
                  <label>Created</label>
                  {dayjs(data.created_at).format('MMMM Do, h:m A')}
                </Field>
                <WideField>
                  <label>Customer Comments</label>
                  {data.customer_comment}
                </WideField>
              </Fields>
            </Card>
            <OuterCard>
              <h2>Return Items</h2>

              <InnerCard>
                <Items id="items-table">
                  <thead>
                    <tr>
                      <th>SKU</th>
                      <th>Product</th>
                      <th>Quantity</th>
                      <th>Return Reason</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.items?.map((item: RmaItem, index) => (
                      <tr key={item.sku + index}>
                        <td>{item.sku}</td>
                        <td>{item.product_name}</td>
                        <td>{item.quantity}</td>
                        <td>{item.reason_for_return}</td>
                      </tr>
                    ))}
                  </tbody>
                </Items>
              </InnerCard>
            </OuterCard>
            <OuterCard>
              <h2>Tracking Numbers</h2>
              {data.trackingNumbers?.length > 0 && (
                <InnerCard>
                  <WideField>
                    <label>Tracking Number(s)</label>
                    {data.trackingNumbers
                      .map((tn) => tn.tracking_number)
                      .join(', ')}
                  </WideField>
                </InnerCard>
              )}
            </OuterCard>
          </Card>
        </Container>
      </>
    );

  return (
    <Container>
      <SEO title={`Error with RMA`} />
      <h1>There was an error loading the RMA information.</h1>
    </Container>
  );
};

export default RmaPage;

const LabelError = styled.div`
  padding: 0.5rem;
  background: #ffe6e6;
  border: 2px solid #ffafaf;
  border-radius: 0.25rem;
  color: #e34848;
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 1rem;
`;

const TrackingNumbers = styled.div`
  padding: 0.5rem;
  background: #e7ffef;
  border: 2px solid #b6ddc2;
  border-radius: 0.25rem;
  color: #3d7f61;
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 1rem;

  span {
    display: block;
    line-height: 2;
  }
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Container = styled.div`
  margin: auto;
  max-width: 1100px;
  height: 100%;
  padding: 1rem 1rem 5rem;
`;

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;

  h1 {
    margin: 0;
    font-size: 38px;
  }

  button {
    cursor: pointer;
    color: hsl(40 5.9% 10%);
    background: hsl(240deg 4.76% 95.88%);
    border-radius: 6px;
    padding: 0.5rem 1rem;
    font-family: var(--font-secondary);
    font-size: 0.875rem;
    font-weight: 500;

    :hover {
      background: hsl(240deg 4.76% 97%);
    }
  }

  svg {
    margin-left: 2px;
    color: #57bd2b;
    font-size: 18px;
    margin-bottom: 4px;
  }

  @media (max-width: 750px) {
    display: block;
    margin-top: 1rem;
    padding-bottom: 1rem;

    h1 {
      font-size: 24px;
    }

    button {
    }
  }
`;

const Status = styled.label<{ statusColor?: string }>`
  font-size: 0.875rem;
  font-weight: 500;
  background: ${(props) => (props.statusColor ? props.statusColor : 'gray')};
  border-radius: 20px;
  color: var(--text-color-primary);
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  margin-left: 10px;

  @media (max-width: 750px) {
    padding: 3px 14px;
    margin-left: 12px;
    font-size: 11px;
    border-radius: 15px;
  }
`;

const Fields = styled.div`
  padding: 0;
  margin: 0;
  font-size: 14px;

  @media (min-width: 750px) {
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
    gap: 1.5rem;
    font-size: 16px;
  }
`;

const Field = styled.div`
  label {
    font-family: var(--font-secondary);
    font-size: 0.875rem;
    font-weight: 500;
    color: gray;
    display: block;
    margin-bottom: 0.25rem;
  }

  font-size: 1.15rem;
  font-weight: 500;

  @media (max-width: 750px) {
    margin-bottom: 1rem;
    label {
      font-size: 12px;
      margin-bottom: 3px;
    }
  }
`;

const WideField = styled(Field)`
  grid-column: 1 / 4;
`;

const Card = styled.div`
  border: 1px solid rgb(228, 228, 231);
  padding: 1rem 1.5rem 2rem;
  border-radius: var(--card-radius);
  margin-top: 2rem;
  box-shadow: var(--card-shadow);

  h2 {
    font-weight: 600;
    font-size: 1.25rem;
    margin: 0;
    padding-bottom: 1.5rem;
  }

  @media (max-width: 750px) {
  }
`;

const OuterCard = styled(Card)`
  padding-inline: 0;

  h2 {
    padding-inline: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgb(228, 228, 231);
  }
`;

const InnerCard = styled.div`
  padding: 1.5rem;
  overflow-x: auto;
`;

const Items = styled.table`
  width: 100%;
  min-width: 550px;
  background: white;
  th {
    text-transform: uppercase;
    font-family: var(--font-secondary);
    font-size: 13px;
    color: gray;
    text-align: left;
    border-bottom: 1px solid lightgray;
    padding: 0.5rem;
  }
  td {
    padding: 1rem;
    font-weight: 500;
  }

  tr {
    border-bottom: #e4e4e4 1px solid;

    :last-child {
      border-bottom: none;
    }
  }

  @media (max-width: 750px) {
    th {
      font-size: 12px;
    }

    font-size: 14px;
  }
`;

const LabelModal = styled.div`
  background: blue;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--modal-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LabelForm = styled.form`
  width: 400px;
  background: white;
  box-shadow: var(--modal-box-shadow);
  padding: 1.5rem;
  margin-bottom: 2rem;

  fieldset {
    margin: 0;
    width: 100%;
    border: none;
    padding: 0;
    margin-bottom: 0.8rem;
  }

  label {
    font-size: 13px;
    color: #545454;
    display: block;
    font-weight: 600;
    margin-bottom: 2px;
  }

  input,
  select {
    height: 40px;
  }

  input,
  select,
  textarea {
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid grey;
    width: 100%;
    font-size: 14px;
  }

  @media (max-width: 1024px) {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-top: calc(var(--mobile-navbar-height) + 1rem);
  }
`;

const Instructions = styled.p`
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
`;

const ErrorMessage = styled.p`
  color: var(--error-color);
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 0;
`;

const GenerateButton = styled.button`
  color: whitesmoke;
  background: #293130;
  padding: 0.5rem 1.5rem;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 500;

  :hover {
    background: #525b5a;
  }
`;

const FinishButton = styled(GenerateButton)`
  background: transparent;
  border: 1px solid #293130;
  color: #293130;

  :hover {
    background: whitesmoke;
  }
`;

const LabelButtonWrapper = styled.div`
  padding: 1rem 0;
`;

const LabelButtons = styled.div`
  display: flex;
  gap: 1rem;
`;

const LabelAndPackingSlipButtons = styled.div`
  display: flex;
  gap: 0.75rem;

  @media (max-width: 1024px) {
    display: grid;
    justify-items: start;
    margin-top: 1.5rem;
  }
`;

const TitleAndStatus = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
  line-height: 1;

  img {
    width: 72px;
    padding-top: 3px;
  }
`;
