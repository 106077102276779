import React from 'react';
import { Router } from '@reach/router';
import RmaPage from '../components/RmaPage';

const Rmas = () => {
  return (
    <Router>
      <RmaPage path="/rmas/:id" />
    </Router>
  );
};

export default Rmas;
